export const queries = [
    // {
    //     name: "Inventory",
    //     description: "List all resources in your account",
    //     value: [
    //         "MATCH (Source:Resource)",
    //         "RETURN Source.Name AS Name,",
    //         "Source.Arn AS ARN"
    //     ]
    // },
    // {
    //     name: "Administrators",
    //     description: "List resources that effectively have full access to your account",
    //     value: [
    //         "MATCH Path=(Source:Resource)-[:TRANSITIVE|ATTACK*1..]->(Target:Admin)",
    //         "RETURN Source.Name AS Name, Source.Arn AS Arn"
    //     ]
    // },
    // {
    //     name: "Public access",
    //     description: "Shows actions that can be performed by anyone",
    //     value: [
    //         "MATCH Actions=(Source:`AWS::Account`)-[Action:ACTION]->(Target:Resource)",
    //         "WHERE Source.Name = 'All AWS Accounts'",
    //         "AND Action.Effect = 'Allow'",
    //         "RETURN Actions"
    //     ]
    // },
    // {
    //     name: "Public buckets (read access)",
    //     description: "Shows buckets that can be read anonymously",
    //     value: [
    //         "MATCH Actions=(Source:`AWS::Account`)-[Action:ACTION]->(Target:`AWS::S3::Bucket`)",
    //         "WHERE Source.Name = 'All AWS Accounts'",
    //         "AND Action.Access = 'Read'",
    //         "AND Action.Effect = 'Allow'",
    //         "RETURN Actions"
    //     ]
    // },
    // {
    //     name: "Public roles (assumable)",
    //     description: "Shows roles that can be assumed anonymously",
    //     value: [
    //         "MATCH Actions=(Source:`AWS::Account`)-[Action:ACTION]->(Target:`AWS::Iam::Role`)",
    //         "WHERE Source.Name = 'All AWS Accounts'",
    //         "AND Action.Effect = 'Allow'",
    //         "AND Action.Name =~ '.*sts:Assume.*'",
    //         "RETURN Actions"
    //     ]
    // },
    {
        name: "Kubernetes resources",
        description: "Returns all Kubernetes resources",
        value: [
            "MATCH (resource) WITH resource, LABELS(resource) AS ls UNWIND ls AS l",
            "WITH resource, l WHERE l =~ 'Kubernetes.*'",
            "RETURN resource"
        ]
    },
    {
        name: "Kubernetes pods",
        description: "Returns all Kubernetes pods",
        value: [
            "MATCH (resource:KubernetesPod)",
            "RETURN resource"
        ]
    },
    {
        name: "Kubernetes resource types",
        description: "Returns one of each Kubernetes resource type",
        value: [
            "MATCH (resource)",
            "WITH resource, LABELS(resource) AS ls",
            "UNWIND ls AS l",
            "WITH resource, l",
            "WHERE l =~ 'Kubernetes.*'",
            "WITH l, COLLECT(resource) AS resources",
            "RETURN l, HEAD(resources) AS resource"
        ]
    },
    {
        name: "Graph schema",
        description: "Returns distinct edge types and their associated resources",
        value: [
            "MATCH ()-[edge]-()",
            "WITH edge, edge.name AS l",
            "WITH l, COLLECT(edge) AS edge",
            "WITH l, HEAD(edge) AS edge",
            "OPTIONAL MATCH p=()-[edge]-()",
            "RETURN p, edge, l"
        ]
    },
    {
        name: "Missing 'names's",
        description: "Returns specific nodes missing the name property",
        value: [
            "MATCH (resource)",
            "WHERE ANY(l IN LABELS(resource) ",
            "   WHERE l STARTS WITH 'Kubernetes' ",
            "   OR l =~ 'GCPUser|GCPGroup|GCPRole'",
            "   )",
            "AND resource.name IS NULL",
            "RETURN resource"
        ]
    },
    {
        name: "Clear database",
        description: "Deletes all nodes and edges",
        value: [
            "MATCH (resource)",
            "/* WHERE ANY(l IN LABELS(resource) WHERE NOT l STARTS WITH 'Keycloak') */", 
            "/* WHERE ANY(l IN LABELS(resource) WHERE l STARTS WITH 'Keycloak') */", 
            "DETACH DELETE (resource)",
            "RETURN COUNT(resource) AS deleted"
        ]
    },
    {
        name: "Find duplicates",
        description: "Finds all nodes that share the same value for 'id'",
        value: [
            "MATCH (resource) WITH  LABELS(resource)[0] AS l, resource.id AS key, COLLECT(resource) AS duplicates",
            "WHERE SIZE(duplicates) > 1",
            "RETURN l, key, duplicates"
        ]
    },
    {
        name: "Realmless resources",
        description: ["Finds all keycloak nodes that do not belong to a realm, i.e. users added through roles"],
        value: [
            "MATCH (resource) ",
            "WHERE ANY(l IN LABELS(resource) ",
            "   WHERE l STARTS WITH 'Keycloak')",
            "AND NOT (resource)-[]-(:KeycloakRealm) ",
            "AND NOT (resource:KeycloakRealm) ",
            "RETURN resource",
        ]
    },
    {
        name: "Sample expandible kubernetes resources ",
        description: ["Returns 10 Kubernetes resources which can be expanded"],
        value: [
            "MATCH (resource) ",
            "WHERE ANY(l IN LABELS(resource)",
            "   WHERE l STARTS WITH 'Kubernetes'",
            ")",
            "AND (resource)--()",
            "RETURN resource LIMIT 10"
        ]
    },
    {
        name: "Access report",
        description: ["Returns all users groups and roles in the internal realm"],
        value: [
            "WITH ['internal'] AS realms",
            "MATCH (realm:KeycloakRealm)-[:ASSOCIATIVE{name:'HAS_USER'}]->(user:KeycloakUser)",
            "WHERE realm.name IN realms",
            "WITH realm, user",
            "MATCH path=allShortestPaths((user:KeycloakUser)-[edges:TRANSITIVE*1..4]->(role:KeycloakRole))",
            "WHERE ALL(edge IN RELATIONSHIPS(path) WHERE edge.name IN ['SUBGROUP_OF', 'MEMBER_OF', 'HAS_ROLE'])",
            "WITH DISTINCT",
            "    realm.name AS realm,",
            "    user.name AS user,",
            "    [group IN NODES(path) WHERE group:KeycloakGroup | group.name] AS groups,",
            "    role.name AS role",
            "ORDER BY realm, user, groups, role",
            "RETURN realm, user, groups, COLLECT(role) AS roles"
        ],

    },
    {
        name: "K8s Role bindings",
        description: ["Returns all GCP user role bindings"],
        value: [
            "MATCH paths=ShortestPath((user:GCPUser)-[:TRANSITIVE*0..]->(binding:KubernetesRoleBinding))",
            "OPTIONAL MATCH (binding)-[:ASSOCIATIVE{name:'BOUND_TO'}]->(role:KubernetesClusterRole)",
            "OPTIONAL MATCH (binding)<-[:ASSOCIATIVE{name:'CONTAINS'}]-(namespace:KubernetesNamespace)",
            "WITH DISTINCT user, namespace, role,",
            "    apoc.convert.fromJsonMap(",
            "        apoc.convert.fromJsonMap(",
            "            role.metadata",
            "        ).annotations['kubectl.kubernetes.io/last-applied-configuration']",
            "    ).rules AS rules",
            "UNWIND rules AS rule",
            "RETURN user.name, namespace.name, role.name, rule",
        ],
    },
    {
        name: "Default preload query",
        description: ["Returns max 1000 nodes, subdivided by each label"],
        value: [
            "// Overwrite UI preload query:",
            "WITH 1000 AS MAX_NODES",
            "CALL db.labels() YIELD label",
            "WITH MAX_NODES, COLLECT(label) AS labels",
            "UNWIND labels AS label",
            "MATCH (n)",
            "WHERE label IN LABELS(n)",
            "WITH",
            "    MAX_NODES,",
            "    label,",
            "    COUNT(n) AS total_nodes_per_label,",
            "    labels",
            "WITH",
            "    labels,",
            "    label,",
            "    total_nodes_per_label,",
            "    CASE",
            "        WHEN SIZE(labels) > MAX_NODES THEN 1",
            "        ELSE MAX_NODES / SIZE(labels)",
            "    END AS nodes_per_label",
            "MATCH (n)",
            "WHERE label IN LABELS(n)",
            "WITH",
            "    label,",
            "    COLLECT(n) AS nodes,",
            "    TOINTEGER(nodes_per_label) AS nodes_per_label",
            "RETURN",
            "    nodes[0..nodes_per_label] AS nodes",
            "ORDER BY",
            "    label"
        ],
    }

];
